<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：工序管理视图页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀,康泽鑫
	最后修改：2021-07-30
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview AppTreeTableContent returnview" id="processManage">
    <!-- 左边列表 -->
    <div class="AppLeftBox">
      <!-- 分类 -->
      <div class="content_left">
        <!-- 分类列表 -->
        <div class="left_list">
          <div class="left_all_btn" :class="{'left_all_btn_sel':sel_searchBtn == 0}" @click="getProcessesData(0)">
            <div>
              <i class="all_icon el-icon-menu"></i>
              <span>全部分类</span>
            </div>
          </div>
          <div>
            <el-tree :data="BomType" :props="defaultProps" node-key="id" ref="tree" default-expand-all
              :expand-on-click-node="false" @current-change="getProcessesById($event)">
              <span class="custom-tree-node" slot-scope="{ node, BomType}">
                <span>{{ node.label }}</span>
                <span>
                  <el-button type="text" size="mini">
                    <i class="el-icon-more"></i>
                  </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>

    </div>

    <!-- 资料分类拉伸 -->
    <div class="AppHandelBox"></div>

    <!-- 高级查询操作框 -->
    <div class="AppRightBox">
      <div class="billingview_headoperate">
        <!-- 标题 -->
        <div class="head_operateList">
          <ul class="list">
            <li @click="getProcessesData(0)" :class="{'selLi':sel_searchBtn==0}">
              全部
            </li>
            <li @click="getProcessesData(2)" :class="{'selLi':sel_searchBtn==2}">
              自产
            </li>
            <li @click="getProcessesData(3)" :class="{'selLi':sel_searchBtn==3}">
              委外
            </li>
            <li :class="{'selLi':sel_searchBtn==4}">
              <el-popover placement="bottom" width="400" v-model="show_searchBox" trigger="manual">
                <el-form ref="form" :model="offer_searchData" size="mini" label-width="100px">
                  <el-form-item>
                    <div class="billingview_searchTitle">
                      <span>根据以下条件搜索:</span>
                    </div>
                  </el-form-item>
                  <el-form-item label="工序编号:">
                    <el-input v-model="offer_searchData.econding" size="small" placeholder="请输入内容" clearable>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="工序名称:">
                    <el-input v-model="offer_searchData.name" size="small" placeholder="请输入内容" clearable>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="负责部门:">
                    <el-input v-model="offer_searchData.deptName" size="small" placeholder="请输入内容"
                      @focus="judgeRelatedData(1,1)" clearable></el-input>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="负责员工:">
                    <el-input v-model="offer_searchData.userName" size="small" placeholder="请输入内容"
                      @focus="judgeRelatedData(2,1)" clearable>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="创建起止日期:">
                    <el-col :span="10">
                      <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.startTime"
                        style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="3" :push="1"> - </el-col>
                    <el-col :span="10">
                      <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.endTime"
                        style="width: 100%;"></el-date-picker>
                    </el-col>
                  </el-form-item>
                  <el-form-item>
                    <div class="billingview_searchBtnBox">
                      <el-button type="primary" @click="getProcessesData(4)">搜索</el-button>
                      <el-button @click="show_searchBox=false">取消</el-button>
                    </div>
                  </el-form-item>
                </el-form>
                <span slot="reference" class="billingview_searchBut" @click="show_searchBox=!show_searchBox">
                  查询<i class="el-icon-search"></i>
                </span>
              </el-popover>
            </li>
          </ul>
        </div>

        <!-- 操作列表 -->
        <div class="head_operateList">
          <ul class="list">
            <li @click="show_addBox">
              <i class="el-icon-plus"></i>新增
            </li>
            <li>
              <i class="el-icon-document-copy"></i>复制
            </li>
            <li>
              <i class="el-icon-plus"></i>导出
            </li>
          </ul>
        </div>
      </div>

      <!-- 报价表格 -->
      <div class="billingview_tablewrapper billing_table">
        <el-table :data="tableData" height="100%" border style="width: 100%">
          <!-- 索引 -->
          <el-table-column type="index" fixed width="50"></el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" fixed width="80">
            <template slot-scope="scope">
              <i class="el-icon-edit-outline icon" title="修改" @click="show_update(scope.row)"></i>
              <i class="el-icon-delete icon" title="删除" @click="delectProcess(scope.row)"></i>
            </template>
          </el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="name" label="工序名称" sortable width="120"></el-table-column>
          <el-table-column prop="encoding" label="工序编码" sortable width="120"></el-table-column>
          <el-table-column prop="manufactureTypeName" label="类别" width="80"></el-table-column>
          <el-table-column prop="user_name" label="负责人" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="vible">{{scope.row.user_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dept_name" label="负责部门" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="vible">{{scope.row.dept_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="supplier_name" label="供应商" width="240"></el-table-column>
          <el-table-column prop="labour_cost" label="工价" width="120"></el-table-column>
          <el-table-column prop="cost_type_name" label="计费方式" width="80"></el-table-column>
          <el-table-column prop="hours" label="参考用时" width="80"></el-table-column>
          <el-table-column prop="fileName" label="图纸" width="180">
          </el-table-column>
          <el-table-column prop="remark" label="备注" min-width="100"></el-table-column>
        </el-table>
      </div>

      <!-- 表格分页 -->
      <div class="billing_tablePage">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size.sync="currentPageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 部门选择弹框组件 -->
    <treeDepartment @SelectedData="getDepartmentData" v-if="show_departmentBox"></treeDepartment>

    <!-- 负责人选择弹框组件 -->
    <personnelList @SelectedData="getPersonnelData" :defaultSelected="defaultSelected" v-if="show_personnelbox">
    </personnelList>

    <!-- 供应商选择组件-->
    <supplierList @SelectedData="getSupplierData" v-if="show_supplierBox"></supplierList>

    <!-- 工序新增编辑弹框 -->
    <mesProcessEdit :operatedState="operatedState" :mesProcessData="mesProcessData"
      @getMesProcessData="getMesProcessData" v-if="show_processform">
    </mesProcessEdit>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //请求相关
  import api from '@/network/system/dictionary'; //数据字典相关请求
  import req from '@/network/production/production'; //生产管理相关请求

  // 导入组件
  import treeDepartment from "@/components/commComponent/tree/tree_department.vue"; //部门组件
  import personnelList from "@/components/commComponent/list/list_personnel.vue"; //负责人组件
  import supplierList from "@/components/commComponent/list/list_supplier.vue"; //供应商组件
  import mesProcessEdit from "@/components/commComponent/dialog/mes_process_edit.vue"; //工序新增编辑组件

  export default {
    name: 'processManage_view',
    data() {
      return {
        // 界面数据
        BomType: [], //工序分类数据
        sel_searchBtn: 0, //当前选中查询条件(0:全部 1:分类  2:高级查询)
        operatedState: 0, //判断是修改还是新增
        mesProcessData: {}, //当前编辑的工序数据
        selectClassfiy: '', //选中分类
        tableData: [], //传入表格组件的数据

        //控制开关
        show_searchBox: false, //是否显示搜索
        show_processform: false, //是否显示新增修改弹窗
        show_departmentBox: false, //是否显示部门弹框
        show_personnelbox: false, //是否显示负责人弹框
        show_supplierBox:false,//是否显示供应商弹框
        show_perordeptType: 0, //控制加载的是联系人/部门弹框数据(0:新增修改选择  1:搜索选择)

        //传入子组件的数据
        defaultSelected: [], //默认选中

        //loding加载
        loading: '',

        //el-tree配置
        defaultProps: {
          children: 'childrs',
          label: 'name',
        },

        //分页相关
        total: 0, //合计
        currentPage: 1, //当前页码
        currentPageSize: 20, //当前页数

        // 高级搜索框数据
        offer_searchData: { //高级搜索数据
          econding: '', //编号
          name: '', //工序名
          deptName: '', //负责部门
          userName: '', //负责人
          startTime: '', //创建开始时间
          endTime: '', //创建结束时间
          deptId: '', //部门id
          userId: '' //用户
        },
      }
    },

    created() {
      // 初始化界面所需参数(vuex数据)
      this.initVuextData();
      //默认数据
      this.getProcessesData(0);
    },

    mounted() {
      // 初始化分类栏目拉伸
      this.commonJsExtend.getDunamicWidth();
    },
    watch: {},
    computed: {
      ...mapState({
        ProductionBomType: state => state.system.ProductionBomType, //BOM分类
        ManufactureType: state => state.system.ManufactureType, //生产类型
        userPowerInfo: state => state.user.userPowerInfo, //权限
      })
    },

    methods: {
      ...mapMutations([
        'SHOW_DEPARTMENTBOX', //部门选择组件
        'SHOW_PERSONNELBOX', //负责人选择组件
        'SHOW_SUPPLIERBOX', //供应商选择组件
      ]),

      ...mapActions([
        'getAllBomType', //BOM分类
        'getAllManufactureType', //生产类型
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化界面所需数据vuex数据 */
      async initVuextData() {
        //判断用户权限
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
        }
        //工序分类数据
        if (this.ProductionBomType.length == 0) {
          await this.getAllBomType();
        }
        //生产类型
        if(this.ManufactureType.length == 0){
          await this.getAllManufactureType();
        }
        //获取已启用的bom分类
        this.ProductionBomType.forEach((item, index) => {
          if (item.is_enable == 1) {
            this.BomType.push(item);
          }
        })
      },

      /* 根据Id查询数据 type:查询方式(0:全部 1:分类查询  2:自产  3:委外  4:高级查询) */
      getProcessesData(type) {
        //判断是否有查询权限
        if (this.commonJsExtend.isHaveThePower(16, 2, 1, '查询')) {
          this.tableData = []
          return
        }

        //获取当前查询方式
        this.sel_searchBtn = type;

        //定义公共查询条件
        var data = {
          pageIndex: this.currentPage,
          pageSize: this.currentPageSize,
        }

        // 判断查询类型
        if(type == 0){//全部
        }else if(type == 1){//分类
          if (this.currentClassfiyId != -1) {
            data.process_type_id = this.currentClassfiyId;
          }
        }else if (type == 2) { //自产
          let id = this.commonJsExtend.getDictMatchingDataByCode('DICT-SMM-001', this.ManufactureType).id
          data.manufacture_type_id = id;
        } else if (type == 3) { //委外
          let id = this.commonJsExtend.getDictMatchingDataByCode('DICT-SMM-002', this.ManufactureType).id
          data.manufacture_type_id = id;
        } else if (type == 4) { //高级查询
          //定义高级查询条件
          let {
            econding, //编号
            name, //工序名
            deptName, //负责部门
            userName, //负责人
            startTime, //创建开始时间
            endTime, //创建结束时间
            deptId, //部门id
            userId //用户
          } = this.offer_searchData;

          //判断日期是否合法
          if (!startTime && !endTime) {
            if (!this.commonJsExtend.getDifferTime(startTime, endTime)) {
              return
            }
            data.startTime = startTime;
            data.endTime = endTime;
          }
          //判断其它查询条件
          //编号
          if (econding != '') {
            data.encoding = econding
          }
          // 工序名
          if (name != '') {
            data.name = name
          }
          //负责部门
          if (deptName != '') {
            data.dept_id = deptId
          }
          //负责人
          if (userName != '') {
            data.user_id = userId
          }
          //创建开始时间
          if (startTime != '') {
            data.startTime = startTime
          }
          //创建结束时间
          if (endTime != '') {
            data.endTime = endTime
          }

          //关闭搜索弹框
          this.show_searchBox = false;
        }
        //加载loding
        this.loading = this.commonJsExtend.customLoading("#processManage", 3);
        //发送请求条件查询
        req.searchProcessManageById(data).then(res => {
          if (res.code == 200) {
            this.total = res.data.total
            res.data.rows.forEach(item => {
              //获取文件名
              let img = item.img_url.split("@@@")
              this.$set(item, 'fileName', img[1])
            })
            this.tableData = res.data.rows
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          //关闭loading加载框
          this.loading.close()
        })
      },

      /* 显示新增弹框 */
      show_addBox() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(16, 2, 2, '新增')) {
          return
        }
        //设置类型为新增
        this.operatedState = 0;
        //显示弹框
        this.show_processform = true;
      },

      /* 显示更新弹框 赋值 */
      show_update(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(16, 2, 4, '修改')) {
          return
        }
        //获取需要修改的数据源
        this.mesProcessData = data;
        //设置类型为修改
        this.operatedState = 1;
        //显示修改弹框
        this.show_processform = true;
        return
      },

      /* 获取添加或编辑的工序数据 */
      getMesProcessData(type,data) {
        //判断组件操作方式
        if(type == 0){//确定
          //判断当前生产类型为委外时才有供应商
          let supplier_id = ''; //供应商id
          let supplier_name = ''; //供应商名称
          if (data.typeData.code == 'DICT-SMM-002') {
            supplier_id = data.supplierId;
            supplier_name = data.supplier;
          }
          //获取公共数据
          var processData = {
            encoding: data.encoding, //编码
            cost_type_id: data.costId, //计费方式Id
            dept_id: data.departmentId, //负责部门
            dept_name: data.department, //负责部门
            hours: data.time, //标准工时
            total_labour_cost: data.total_labour_cost, //总工价
            manufacturing_allocation_cost:data.manufacturing_allocation_cost+"@@@"+data.manufacturingType,//制造分摊成本
            manage_cost_allocation:data.manage_cost_allocation+"@@@"+data.manageType,//管理分摊成本
            other_apportioned_costs:data.other_apportioned_costs+"@@@"+data.otherType,//其它分摊成本
            min_work_amount: data.min_work_amount, //最低开工费用
            min_work_number: data.min_work_number, //最低开工费配额
            img_url: data.ImgUrlArr.join(","), //工序图纸路径
            is_enable: data.is_enable ? 1 : 0, //是否启用
            labour_cost: data.price, //工价
            manufacture_type_id: data.typeId, //生成类型Id
            name: data.name, //工序名称
            process_type_id: data.classfiyId, //工序分类Id
            user_id: data.personnelId, //负责人Id
            user_name: data.personnel, //负责人Id
            supplier_id: supplier_id, //供应商Id
            supplier_name: supplier_name, //供应商名称
            work_num: data.work_num, //执行人数
            remark: data.remark, //备注
          }
          //判断当前操作类型
          if (this.operatedState == 0) { //新增
            //发送请求
            req.addProcessManage(processData).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '数据新增成功',
                  duration: this.elDuration
                })
                //关闭组件
                this.show_processform = false;
                //获取新的列表数据
                this.getProcessesData(this.sel_searchBtn);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.operatedState == 1) { //修改
            processData.id = data.id;
            processData.name = data.old_name;
            processData.new_name = data.name;
            //发送请求
            req.updateProcessManage(processData).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '数据修改成功',
                  duration: this.elDuration
                })
                //关闭组件
                this.show_processform = false;
                //获取新的列表数据
                this.getProcessesData(this.sel_searchBtn);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        }else if(type == 1){//取消
          this.show_processform = false;
        }
      },

      /* 删除工序 */
      delectProcess(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(16, 2, 3, '删除')) {
          return
        }
        this.$confirm('您确定要删除该工序吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          //加载loading框
          req.delectProcessManage({
            id: data.id
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功',
              })
              //获取新的列表数据
              this.getProcessesData(this.sel_searchBtn);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
      },

      /* 选择bom分类 */
      getProcessesById(data) {
        this.currentClassfiyId = data.id;
        //获取新的列表数据
        this.getProcessesData(1);
      },

      /*页数改变时的回调*/
      handleSizeChange(val) {
        this.currentPageSize = val;
        //获取新的列表数据
        this.getProcessesData(this.sel_searchBtn);
      },

      /*页码改变时的回调*/
      handleCurrentChange(val) {
        this.currentPage = val;
        //获取新的列表数据
        this.getProcessesData(this.sel_searchBtn);
      },

      /* 获取点击部门数据 */
      getDepartmentData(data) {
        this.show_departmentBox = false;
        let result = this.commonJsExtend.getDeptMsg(data);
        //判断打开方式
        if (this.show_perordeptType == 0) {
          this.formData.department = result.nameStr; //部门
          this.formData.departmentId = result.idStr; //部门id
        } else if (this.show_perordeptType == 1) {
          this.offer_searchData.deptName = result.nameStr; //部门
          this.offer_searchData.deptId = result.idStr; //部门id
        }
        this.$forceUpdate()
      },

      /* 点击获取负责人信息 */
      getPersonnelData(data) {
        //关闭加载的负责人组件信息
        this.show_personnelbox = false;
        this.defaultSelected = data;
        //获取名称,id字符串
        let result = this.commonJsExtend.getPersonnelMsg(data);
        //判断打开方式
        if (this.show_perordeptType == 0) {
          this.formData.work_num = data.length; //获取执行人数
          this.formData.personnel = result.nameStr; //名称str
          this.formData.personnelId = result.idStr; //名称idstr
        } else if (this.show_perordeptType == 1) {
          this.offer_searchData.userName = result.nameStr; //名称str
          this.offer_searchData.userId = result.idStr; //名称idstr
        }
        this.$forceUpdate()
      },

      /* 弹框组件 */
      judgeRelatedData(type, operateType) {
        //获取打开弹框方式
        this.show_perordeptType = operateType;
        //判断显示弹框类型
        if (type == 1) {
          this.show_departmentBox = true;
          this.SHOW_DEPARTMENTBOX(true); //部门
        } else if (type == 2) {
          this.show_personnelbox = true;
          this.SHOW_PERSONNELBOX(true); //负责人
        }
      },

      /* 获取选择供应商数据 */
      getSupplierData(data) {
        let supplierNameArr = [];
        let supplierIdArr = [];
        if (data.length > 0) {
          data.forEach((item, index) => {
            supplierNameArr.push(item.fullName);
            supplierIdArr.push(item.id);
          })
        }
        this.formData.supplier = supplierNameArr.join(",");
        this.formData.supplierId = supplierIdArr.join(",");
      },
    },
    components: {
      treeDepartment,
      personnelList,
      supplierList,
      mesProcessEdit
    }
  }
</script>


<style lang="less" scoped>
  .vible {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    display: flex;
    margin-top: 4px;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  }

  .el-upload-list--picture-card {
    display: flex;
    flex-wrap: wrap;
  }

  .el-upload-list--picture-card .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
  }

  .data {
    div {
      display: flex;
    }
  }

  .billingview {
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    padding: 10px 0 !important;
  }
</style>
<!-- <style lang="less" src="@/less/Comm/comm_billing.less"></style> -->
