// 程序版本：V3.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：系统.生产相关请求
// 开始时间：2021-05-06
// 开发人员：万攀
// 最后修改：2021-05-06
// 备注说明：如需修改请联系开发人员

import {
  request
} from "../request.js";
import qs from 'qs';

export default {
  //生产单列表相关
  addMesMain(data) { //新增生产单
    return request({
      method: 'POST',
      url: '/mes/addMesMain',
      data,
    })
  },
  updateMesMain(data) { //修改生产单
    return request({
      method: 'POST',
      url: '/mes/updateMesMain',
      data,
    })
  },
  updateMesProcessSubmit(data) { //提交审批
    return request({
      method: 'POST',
      url: '/mes/updateMesProcessSubmit',
      data,
    })
  },
  updateMesProcessStatusYes(data) { //提交并审批
    return request({
      method: 'POST',
      url: '/mes/updateMesProcessStatusYes',
      data,
    })
  },
  findMesMainByCondition(data) { //条件查询生产单
    return request({
      method: 'POST',
      url: '/mes/findMesMainByCondition',
      data,
    })
  },
  findMesMainDetailByCondition(data) { //条件查询生产单详情
    return request({
      method: 'POST',
      url: '/mes/findMesMainDetailByCondition',
      data,
    })
  },
  findMesMainById(data) { //根据生产单id查询生产单详情(未关联物料信息)
    return request({
      method: 'POST',
      url: '/mes/findMesMainById',
      data,
    })
  },
  findMesMainRelateById(data) { //根据生产单id查询生产单详情(有关联物料,工序等信息,物料有树形结构)
    return request({
      method: 'POST',
      url: '/mes/findMesMainRelateById',
      data,
    })
  },
  findMesMainDetail(data) { //根据生产单id查询生产单详情(有关联物料,工序等信息,物料无树形结构)
    return request({
      method: 'POST',
      url: '/mes/findMesMainDetail',
      data,
    })
  },
  updateMesMainStatusIsSubmit(data) { //提交生产计划单
    return request({
      method: 'POST',
      url: '/mes/updateMesMainStatusIsSubmit',
      data,
    })
  },
  updateMesMainStatusNo(data) { //驳回生产计划单
    return request({
      method: 'POST',
      url: '/mes/updateMesMainStatusNo',
      data,
    })
  },
  againstMesBill(data) { //反审生产计划单
    return request({
      method: 'POST',
      url: '/mes/againstMesBill',
      data,
    })
  },
  updateMesMainStatusYes(data) { //生产审批
    return request({
      method: 'POST',
      url: '/mes/updateMesMainStatusYes',
      data,
    })
  },
  modifyMesMainDisable(data) { //作废生产
    return request({
      method: 'POST',
      url: '/mes/modifyMesMainDisable',
      data,
    })
  },
  addMesMainByOrder(data) { //根据订单生成多个生产单
    return request({
      method: 'POST',
      url: '/mes/addMesMainByOrder',
      data,
    })
  },
  addMesMainByMrp(data) { //根据mrp添加生产计划单
    return request({
      method: 'POST',
      url: '/mes/addMesMainByMrp',
      data,
    })
  },
  addMesMainExport(data) { //生产生产计划单导出文件
    return request({
      method: 'POST',
      url: '/orderCommon/addMesMainExport',
      data,
    })
  },
  exportMesList(data) { //导出生产计划单单据列表
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportMesList',
      data,
    })
  },
  findMesProcessByMesMainIdToPrint(data) { //查询-根据生产单id查询工序列表信息(生产计划单列表按工序打印)
    return request({
      method: 'POST',
      url: '/mes/findMesProcessByMesMainIdToPrint',
      data,
    })
  },
  findMesIdStrByCondition(data) { //查询-根据条件查询生产计划单id字符串
    return request({
      method: 'POST',
      url: '/mes/findMesIdStrByCondition',
      data,
    })
  },
  offerMesMain(data) { //根据货品id查询相关计划单交易信息
    return request({
      method: 'POST',
      url: '/mes/offerMesMain',
      data,
    })
  },
  exportmesMain(data) { //打印-打印生产通知单列表
    return request({
      method: 'POST',
      url: '/orderCommon/AllListExport/exportmesMain',
      data,
    })
  },


  //生产单关联单据查询
  findMesOriginBill(data) { //查询-查询生产源单单据
    return request({
      method: 'POST',
      url: '/mes/findMesOriginBill',
      data,
    })
  },
  findDepotMainGet(data) { //查询-查询生产领料单单据
    return request({
      method: 'POST',
      url: '/mes/findDepotMainGet',
      data,
    })
  },
  findDepotMainReturn(data) { //查询-查询生产退料单单据
    return request({
      method: 'POST',
      url: '/mes/findDepotMainReturn',
      data,
    })
  },
  findDepotMainIn(data) { //查询-查询生产入库单单据
    return request({
      method: 'POST',
      url: '/mes/findDepotMainIn',
      data,
    })
  },
  findCostMainOut(data) { //查询-查询生产付款单单据
    return request({
      method: 'POST',
      url: '/mes/findCostMainOut',
      data,
    })
  },
  findPurchaseMain(data) { //查询-查询生产采购单单据
    return request({
      method: 'POST',
      url: '/mes/findPurchaseMain',
      data,
    })
  },
  findCostInvoice(data) { //查询-查询生产发票单单据
    return request({
      method: 'POST',
      url: '/mes/findCostInvoice',
      data,
    })
  },
  calculationAttributionLevel(data) { //查询-归属层级
    return request({
      method: 'POST',
      url: '/mes/calculationAttributionLevel',
      data,
    })
  },
  calculateTotalMaterialCost(data) { //查询-归属层级
    return request({
      method: 'POST',
      url: '/mes/calculateTotalMaterialCost',
      data,
    })
  },


  //生产执行单相关
  addMesProcess(data) { //合并生产单
    return request({
      method: 'POST',
      url: '/mes/addMesProcess',
      data,
    })
  },
  findMesProcessById(data) { //根据生产计划单工序id查询工序信息
    return request({
      method: 'POST',
      url: '/mes/findMesProcessById',
      data,
    })
  },
  findMesProcessByCondition(data) { //条件查询工序单列表
    return request({
      method: 'POST',
      url: '/mes/findMesProcessByCondition',
      data,
    })
  },
  findByMesProcessIds(data) { //根据工序id查询工序物料信息(生产单工序物料)
    return request({
      method: 'POST',
      url: '/mes/findByMesProcessIds',
      data,
    })
  },
  findMesProcessByIds(data) { //根据多个工序单id查询单据信息
    return request({
      method: 'POST',
      url: '/mes/findMesProcessByIds',
      data,
    })
  },
  addMesProcessExport(data) { //生产执行单导出文件
    return request({
      method: 'POST',
      url: '/orderCommon/addMesProcessExport',
      data,
    })
  },
  updateMesProcess(data) { //修改生产单关联工序
    return request({
      method: 'POST',
      url: '/mes/updateMesProcess',
      data,
    })
  },
  exportMesProcessList(data) { //导出生产执行单单据列表
    return request({
      method: 'POST',
      url: '/orderCommon/billsExport/exportMesProcessList',
      data,
    })
  },

  // 生产单详情相关
  // 生产物料tab页相关
  findMesMaterialByBillId(data) { //根据生产单id查询生产物料信息
    return request({
      method: 'POST',
      url: '/mes/findMesMaterialByBillId',
      data,
    })
  },
  addMesMaterial(data) { //添加生产物料
    return request({
      method: 'POST',
      url: '/mes/addMesMaterial',
      data,
    })
  },
  deleteMesMaterial(data) { //删除生产物料
    return request({
      method: 'POST',
      url: '/mes/deleteMesMaterial',
      data,
    })
  },
  updateMesMaterial(data) { //单个修改生产物料
    return request({
      method: 'POST',
      url: '/mes/updateMesMaterial',
      data,
    })
  },
  updateMesMaterialBatch(data) { //批量修改生产物料
    return request({
      method: 'POST',
      url: '/mes/updateMesMaterialBatch',
      data,
    })
  },
  findMesMaterialById(data) { //根据物料id查询生产物料
    return request({
      method: 'POST',
      url: '/mes/findMesMaterialById',
      data,
    })
  },
  generateDepotBillsByMesMaterial(data) { //根据所选物料快捷生成出入库单据(领料单,退料单,补料单)
    return request({
      method: 'POST',
      url: '/mes/generateDepotBillsByMesMaterial',
      data,
    })
  },

  // 生产工序tab页相关
  findMesProcessListByMesMainId(data){//根据生产单id查询生产单工序列表
    return request({
      method: 'POST',
      url: '/mes/findMesProcessListByMesMainId',
      data,
    })
  },
  findAllMesMainProcessRecord(data) { //查询所有汇报记录
    return request({
      method: 'POST',
      url: '/mes/findAllMesMainProcessRecord',
      data,
    })
  },
  findMesProcessListByCondition(data) { //条件查询工序汇报记录
    return request({
      method: 'POST',
      url: '/mes/findMesProcessListByCondition',
      data,
    })
  },
  addMesMainProcessRecord(data) { //新增生产工序汇报详情
    return request({
      method: 'POST',
      url: '/mes/addMesMainProcessRecord',
      data,
    })
  },
  findMesMainProcessRecordById(data) { //id查询汇报记录
    return request({
      method: 'POST',
      url: '/mes/findMesMainProcessRecordById',
      data,
    })
  },
  modifyProcessRecordDisable(data) { //根据id删除汇报记录
    return request({
      method: 'POST',
      url: '/mes/modifyProcessRecordDisable',
      data,
    })
  },
  updateMesMainProcessRecord(data) { //修改汇报记录
    return request({
      method: 'POST',
      url: '/mes/updateMesMainProcessRecord',
      data,
    })
  },
  //用料登记相关
  addMesMaterialRecord(data) { //添加生产用料登记记录
    return request({
      method: 'POST',
      url: '/mes/addMesMaterialRecord',
      data,
    })
  },

  // 生产工艺tab页相关
  addMesMainTech(data) { //添加生产工艺数据
    return request({
      method: 'POST',
      url: '/mes/addMesMainTech',
      data,
    })
  },

  // 验收记录tab页相关
  findMesMainRecordCondition(data) { //查询验收记录列表页数据
    return request({
      method: 'POST',
      url: '/mes/findMesMainRecordCondition',
      data,
    })
  },
  addMesMainRecord(data) { //新增验收记录
    return request({
      method: 'POST',
      url: '/mes/addMesMainRecord',
      data,
    })
  },
  updateMesMainRecord(data) { //修改验收记录
    return request({
      method: 'POST',
      url: '/mes/updateMesMainRecord',
      data,
    })
  },
  modifyMesMainRecordDisable(data) { //删除验收记录
    return request({
      method: 'POST',
      url: '/mes/modifyMesMainRecordDisable',
      data,
    })
  },

  //生产入库相关
  findDeoptProListById(data) { //新增验收记录
    return request({
      method: 'POST',
      url: '/mes/findDeoptProListById',
      data,
    })
  },

  //生产工序模块相关
  addProcessManage(data) { //添加生产工序
    return request({
      method: 'POST',
      url: '/mes/addMesMainProcess',
      data,
    })
  },
  updateProcessManage(data) { //修改生产工序
    return request({
      method: 'POST',
      url: '/mes/updateMesMainProcess',
      data,
    })
  },
  updateProcessSort(data) { //修改生产工序排序
    return request({
      method: 'POST',
      url: '/mes/updateProcessSort',
      data,
    })
  },
  delectProcessManage(data) { //删除生产工序
    return request({
      method: 'POST',
      url: '/mes/deleteMesMainProcess',
      data,
    })
  },


  //bom管理相关
  searchProcessManageById(data) { //根据BOM分类id查询
    return request({
      method: 'POST',
      url: '/mes/findMesMainProcessByCondition',
      data,
    })
  },
  addMesMainBom(data) { //添加生产BOM物料
    return request({
      method: 'POST',
      url: '/mes/addMesMainBom',
      data,
    })
  },
  updateBomSubmit(data){//提交bom信息
    return request({
      method: 'POST',
      url: '/mes/updateBomSubmit',
      data,
    })
  },
  updateBomVersionInfo(data){//修改bom版本基本信息
    return request({
      method: 'POST',
      url: '/mes/updateBomVersionInfo',
      data,
    })
  },
  updateBomStatusNo(data){//驳回bom信息
    return request({
      method: 'POST',
      url: '/mes/updateBomStatusNo',
      data,
    })
  },
  updateBomStatusYes(data){//审核通过bom信息
    return request({
      method: 'POST',
      url: '/mes/updateBomStatusYes',
      data,
    })
  },
  updateBomDeleteFlag(data){//作废bom版本信息
    return request({
      method: 'POST',
      url: '/mes/updateBomDeleteFlag',
      data,
    })
  },
  findProductHasBomSet(data) { //获取设置了BOM的产品数据
    return request({
      method: 'POST',
      url: '/mes/findProductHasBomSet',
      data,
    })
  },
  findBomVersionByProductId(data) { //根据货品id查询已设bom版本列表
    return request({
      method: 'POST',
      url: '/mes/findBomVersionByProductId',
      data,
    })
  },
  findDefaultBomVersionByProductIds(data) { //根据多个货品id查询已设默认bom版本列表信息
    return request({
      method: 'POST',
      url: '/mes/findDefaultBomVersionByProductIds',
      data,
    })
  },
  findAllMesMainBom(data) { //根据id获取BOM信息
    return request({
      method: 'POST',
      url: '/mes/findAllMesMainBom',
      data,
    })
  },
  modifyMesMainBomDisable(data) { //修改货品主bom状态(作废)(修改为删除)
    return request({
      method: 'POST',
      url: '/mes/modifyMesMainBomDisable',
      data,
    })
  },
  modifyMesMainBomSubClassDisable(data) { //修改货品子bom状态(作废)(修改为删除)
    return request({
      method: 'POST',
      url: '/mes/modifyMesMainBomSubClassDisable',
      data,
    })
  },
  deleteMesMainBom(data) { //作废bom表（暂未用）
    return request({
      method: 'POST',
      url: '/mes/deleteMesMainBom',
      data,
    })
  },
  costReplacement(data) { //重置成本
    return request({
      method: 'POST',
      url: '/mes/costReplacement',
      data,
    })
  },
  updateMesMainBom(data) { //修改生产主bom
    return request({
      method: 'POST',
      url: '/mes/updateMesMainBom',
      data,
    })
  },
  updateMesMainBomSubClass(data) { //修改生产子bom
    return request({
      method: 'POST',
      url: '/mes/updateMesMainBomSubClass',
      data,
    })
  },
  updateMesMainBomProcessMaterialStr(data) { //修改-修改bom版本工序指定合成物料
    return request({
      method: 'POST',
      url: '/mes/updateMesMainBomProcessMaterialStr',
      data,
    })
  },
  addMesMainBomOnly(data) { //添加主物料
    return request({
      method: 'POST',
      url: '/mes/addMesMainBomOnly',
      data,
    })
  },
  addMesMainBomSubClassOnly(data) { //添加子物料
    return request({
      method: 'POST',
      url: '/mes/addMesMainBomSubClassOnly',
      data,
    })
  },
  modifyBomAuditState(data) { //修改bom相关信息
    return request({
      method: 'POST',
      url: '/mes/modifyBomAuditState',
      data,
    })
  },
  addMesMainBomReplaceOnly(data) { //添加bom可替换物料信息(主表)
    return request({
      method: 'POST',
      url: '/mes/addMesMainBomReplaceOnly',
      data,
    })
  },
  addMesMainBomSubClassReplaceOnly(data) { //添加bom可替换物料信息(子表表)
    return request({
      method: 'POST',
      url: '/mes/addMesMainBomSubClassReplaceOnly',
      data,
    })
  },
  findReplaceBom(data) { //根据bomid查询可替换物料列表信息
    return request({
      method: 'POST',
      url: '/mes/findReplaceBom',
      data,
    })
  },
  addMesMainProcessOnly(data) { //单独添加bom所需工序
    return request({
      method: 'POST',
      url: '/mes/addMesMainProcessOnly',
      data,
    })
  },
  addMesBomProcessNum(data) { //添加工序指定物料
    return request({
      method: 'POST',
      url: '/mes/addMesBomProcessNum',
      data,
    })
  },
  deleteBomProcessByProcessId(data) { //删除-根据工序id,版本id删除当前版本已设工序
    return request({
      method: 'POST',
      url: '/mes/deleteBomProcessByProcessId',
      data,
    })
  },
  deleteMesBomProcessNum(data) { //删除工序指定物料
    return request({
      method: 'POST',
      url: '/mes/deleteMesBomProcessNum',
      data,
    })
  },
  findByProcessAndProId(data) { //根据货品id和工序id查询工序指定物料
    return request({
      method: 'POST',
      url: '/mes/findByProcessAndProId',
      data,
    })
  },
  findMesBomProcessNumById(data) { //根据id查询工序指定物料信息
    return request({
      method: 'POST',
      url: '/mes/findMesBomProcessNumById',
      data,
    })
  },
  updateMesBomProcessNum(data) { //根据id修改工序指定物料数据
    return request({
      method: 'POST',
      url: '/mes/updateMesBomProcessNum',
      data,
    })
  },

  //bom导入相关
  importBomByFile(data) { //新增一条导入bom导入记录
    return request({
      method: 'POST',
      url: '/mes/importBomByFile',
      data,
    })
  },
  findImportBomList(data) { //查询bom导入任务列表
    return request({
      method: 'POST',
      url: '/mes/findImportBomList',
      data,
    })
  },
  getImportBomResult(data) { //根据导入bom任务id查询导入结果
    return request({
      method: 'POST',
      url: '/mes/getImportBomResult',
      data,
    })
  },
  findImportBomErrors(data) { //根据导入bom任务id查询导入错误信息列表
    return request({
      method: 'POST',
      url: '/mes/findImportBomErrors',
      data,
    })
  },
  findFinishedProductsById(data) { //根据计划单id查询生产成品出库单
    return request({
      method: 'POST',
      url: '/mes/findFinishedProductsById',
      data,
    })
  },
  saveMesMainTechFile(data) { //生产工艺标准文件保存
    return request({
      method: 'POST',
      url: '/mes/saveMesMainTechFile',
      data,
    })
  },
  reportForAcceptance(data) { //生产工艺标准文件保存
    return request({
      method: 'POST',
      url: '/mes/reportForAcceptance',
      data,
    })
  },
  addParticularMesMaterialRecord(data) { //生产工艺标准文件保存
    return request({
      method: 'POST',
      url: '/mes/addParticularMesMaterialRecord',
      data,
    })
  },
  queryProcessInformation(data) { //查询相关生产工艺信息
    return request({
      method: 'POST',
      url: '/mes/queryProcessInformation',
      data,
    })
  },
  updateRework(data) { //点击返工修改相关状态
    return request({
      method: 'POST',
      url: '/mes/updateRework',
      data,
    })
  },
  completedProcess(data) { //点击返工修改相关状态
    return request({
      method: 'POST',
      url: '/mes/completedProcess',
      data,
    })
  },
  
  /*不良品项相关*/
  findRejectByRecordId(data) { //点击返工修改相关状态
    return request({
      method: 'POST',
      url: '/mesRejects/findRejectByRecordId',
      data,
    })
  },
  /*更新生产工序顺序*/
  updateSortNum(data) { //更新生产工序顺序
    return request({
      method: 'POST',
      url: '/mes/updateSortNum',
      data,
    })
  },
  /*查询生产领料单*/
  findDepotMainProcessGet(data) { //查询生产领料单(生产执行单)
    return request({
      method: 'POST',
      url: '/mes/findDepotMainProcessGet',
      data,
    })
  },
  /*成本核算*/
  costAccounting(data) { //查询生产领料单(生产执行单)
    return request({
      method: 'POST',
      url: '/mes/costAccounting',
      data,
    })
  },
  /*根据生产单id查询成本核算*/
  findCostAccounting(data) { //根据生产单id查询成本核算
    return request({
      method: 'POST',
      url: '/mes/findCostAccounting',
      data,
    })
  },

  /*条件查询成本明细信息*/
  queryingCostDetails(data) { //条件查询成本明细信息
    return request({
      method: 'POST',
      url: '/mes/queryingCostDetails',
      data,
    })
  },

  /*成本明细中成本核算相关*/
  costAccountingCalculation() { //成本明细中成本核算相关
    return request({
      method: 'POST',
      url: '/mes/costAccountingCalculation',
    })
  },
  
  /*查询成本计算状态*/
  calculationStatus() { //查询成本计算状态
    return request({
      method: 'POST',
      url: '/mes/calculationStatus',
    })
  },
  //删除工艺信息
  deleteMesMainTech(data) { //删除工艺信息
    return request({
      method: 'POST',
      url: '/mes/deleteMesMainTech',
      data,
    })
  },
}
